import { SVGProps } from "react";

const Star = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7741 5.2565C18.2391 3.96984 16.4157 3.96984 15.8807 5.2565L12.8307 12.5915L4.90908 13.2265C3.52075 13.3382 2.95742 15.0715 4.01575 15.9782L10.0491 21.1482L8.20575 28.8765C7.88241 30.2315 9.35741 31.3032 10.5474 30.5765L17.3274 26.4348L24.1091 30.5765C25.2974 31.3032 26.7724 30.2315 26.4491 28.8765L24.6057 21.1482L30.6391 15.9782C31.6974 15.0715 31.1341 13.3382 29.7457 13.2282L21.8257 12.5915L18.7741 5.2565V5.2565Z"
      fill="currentColor"
    />
  </svg>
);

export default Star;
