import { format, isToday, isValid, isYesterday } from "date-fns";

export const humanizeTime = (date: string) => {
  if (!date) {
    return null;
  }

  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    return null;
  }

  if (isToday(parsedDate)) {
    return `Today at ${format(parsedDate, "h:mm aa")}`;
  }

  if (isYesterday(parsedDate)) {
    return `Yesterday at ${format(parsedDate, "h:mm aa")}`;
  }

  return format(parsedDate, "MMM d, yyyy");
};
