import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useState } from "react";

import MemberSpotlight from "./MemberSpotlight";

interface OpenSpotlightButtonProps extends TypographyProps {
  userId: number;
  name: string;
  is_birthday: boolean;
  hideMessagingLink?: boolean;
}

const OpenSpotlightButton = ({
  userId,
  name,
  is_birthday,
  hideMessagingLink = false,
  ...typographyProps
}: OpenSpotlightButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { sx, ...restTypographyProps } = typographyProps ?? {};

  return (
    <>
      <Typography
        onClick={() => setIsOpen(true)}
        sx={{
          display: "inline-block",
          fontWeight: "inherit",
          fontSize: "14px",
          cursor: "pointer",
          ...(sx ?? {}),
        }}
        {...restTypographyProps}
      >
        {name}
        {is_birthday ? (
          <Tooltip
            title="🎂 It's the member's birthday. Wish them well during your session!"
            arrow
            placement="top"
            style={{ marginLeft: 2 }}
          >
            <span>🎂</span>
          </Tooltip>
        ) : null}
      </Typography>

      {isOpen ? (
        <MemberSpotlight
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          userId={userId}
          hideMessagingLink={hideMessagingLink}
        />
      ) : null}
    </>
  );
};

export default OpenSpotlightButton;
