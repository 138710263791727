const getQueryKeys = (accessToken: string | undefined) => ({
  all: () => ["clinician-dashboard"],
  appointments: () => [...getQueryKeys(accessToken).all(), "appointments"],
  appointmentsToday: (clinicianEmail?: string) => [
    ...getQueryKeys(accessToken).appointments(),
    "today",
    clinicianEmail,
  ],
  appointmentsThisWeek: (clinicianEmail?: string) => [
    ...getQueryKeys(accessToken).appointments(),
    "this-week",
    clinicianEmail,
  ],
  completedAppointmentHours: () => [
    ...getQueryKeys(accessToken).appointments(),
    "completed-appointment-hours",
  ],
  availability: () => [...getQueryKeys(accessToken).all(), "availability"],
  messages: () => [...getQueryKeys(accessToken).all(), "messages"],
  metrics: () => [...getQueryKeys(accessToken).all(), "metrics"],
  progressOverview: (clinicianEmail?: string) => [
    ...getQueryKeys(accessToken).all(),
    "progress-overview",
    clinicianEmail,
  ],
  treatmentAdherenceBonus: (clinicianEmail?: string) => [
    ...getQueryKeys(accessToken).all(),
    "treatment-adherence-bonus",
    clinicianEmail,
  ],
  utilizationOverview: (
    clinicianEmail?: string,
    quarter?: string,
    selectedWeek?: string,
    selectedDay?: string
  ) => [
    ...getQueryKeys(accessToken).all(),
    "utilization-overview",
    clinicianEmail,
    quarter,
    selectedWeek,
    selectedDay,
  ],
  progressOverviewV2: (clinicianEmail?: string) => [
    ...getQueryKeys(accessToken).all(),
    "progress-overview-v2",
    clinicianEmail,
  ],
  memberOverview: (userId: number) => [
    ...getQueryKeys(accessToken).all(),
    "member-overview",
    userId,
  ],
  weeklyHours: (clinicianEmail: string) => [
    ...getQueryKeys(accessToken).all(),
    "weekly-hours",
    clinicianEmail,
  ],
  ptoHours: (clinicianEmail: string) => [
    ...getQueryKeys(accessToken).all(),
    "pto-hours",
    clinicianEmail,
  ],
  daRules: (clinicianEmail: string) => [
    ...getQueryKeys(accessToken).all(),
    "da-rules",
    clinicianEmail,
  ],
  cancellationDetails: (cancelationId: number, userId: number) => [
    ...getQueryKeys(accessToken).all(),
    "cancellation-details",
    cancelationId,
    userId,
  ],
  ptoWithoutUtilizationCredit: (clinicianEmail: string) => [
    ...getQueryKeys(accessToken).all(),
    "pto-without-utilization-credit",
    clinicianEmail,
  ],
  notoFeedbackForm: (clinicianEmail: string) => [
    ...getQueryKeys(accessToken).all(),
    "noto-clinician-feedback",
    clinicianEmail,
  ],
});

export default getQueryKeys;
