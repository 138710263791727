import { SVGProps } from "react";

const Flag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.19754 5.12544C6.19754 4.81545 6.0744 4.51815 5.8552 4.29895C5.636 4.07975 5.3387 3.9566 5.0287 3.9566C4.71871 3.9566 4.42141 4.07975 4.20221 4.29895C3.98301 4.51815 3.85986 4.81545 3.85986 5.12544V27.723C3.85986 28.033 3.98301 28.3303 4.20221 28.5495C4.42141 28.7687 4.71871 28.8919 5.0287 28.8919C5.3387 28.8919 5.636 28.7687 5.8552 28.5495C6.0744 28.3303 6.19754 28.033 6.19754 27.723V20.8783L8.7799 20.336C11.0352 19.8611 13.3852 20.1758 15.4361 21.2274C18.3098 22.7011 21.6455 22.9941 24.7322 22.044L27.9707 21.0466C28.2093 20.973 28.418 20.825 28.5664 20.6242C28.7147 20.4234 28.7949 20.1804 28.7951 19.9308V6.6839C28.7951 6.51004 28.7563 6.33837 28.6816 6.1814C28.6068 6.02443 28.498 5.88612 28.363 5.77653C28.2281 5.66694 28.0704 5.58884 27.9014 5.54792C27.7324 5.507 27.5564 5.50429 27.3863 5.53999L23.6772 6.32078C21.4944 6.78028 19.2245 6.58344 17.1535 5.75506L16.3711 5.44337C13.8713 4.44284 11.1312 4.20478 8.49626 4.75921L6.19754 5.24233V5.12544V5.12544Z"
      fill="currentColor"
    />
  </svg>
);

export default Flag;
