import { SVGProps } from "react";

const DocumentText = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16089 4.13739C7.49785 4.13739 6.86196 4.40078 6.39312 4.86962C5.92428 5.33847 5.66089 5.97435 5.66089 6.63739V28.304C5.66089 28.9671 5.92428 29.603 6.39312 30.0718C6.86196 30.5407 7.49785 30.804 8.16089 30.804H26.4942C27.1573 30.804 27.7931 30.5407 28.262 30.0718C28.7308 29.603 28.9942 28.9671 28.9942 28.304V13.5057C28.9936 12.8429 28.7299 12.2074 28.2609 11.7391L21.3942 4.86906C21.1618 4.63675 20.8858 4.45255 20.5821 4.327C20.2783 4.20146 19.9529 4.13702 19.6242 4.13739H8.16089ZM11.9109 18.3041C11.5794 18.3041 11.2614 18.4357 11.027 18.6702C10.7926 18.9046 10.6609 19.2225 10.6609 19.5541C10.6609 19.8856 10.7926 20.2035 11.027 20.4379C11.2614 20.6724 11.5794 20.8041 11.9109 20.8041H22.7442C23.0757 20.8041 23.3937 20.6724 23.6281 20.4379C23.8625 20.2035 23.9942 19.8856 23.9942 19.5541C23.9942 19.2225 23.8625 18.9046 23.6281 18.6702C23.3937 18.4357 23.0757 18.3041 22.7442 18.3041H11.9109ZM11.9109 23.304C11.5794 23.304 11.2614 23.4357 11.027 23.6702C10.7926 23.9046 10.6609 24.2225 10.6609 24.554C10.6609 24.8856 10.7926 25.2035 11.027 25.4379C11.2614 25.6724 11.5794 25.804 11.9109 25.804H22.7442C23.0757 25.804 23.3937 25.6724 23.6281 25.4379C23.8625 25.2035 23.9942 24.8856 23.9942 24.554C23.9942 24.2225 23.8625 23.9046 23.6281 23.6702C23.3937 23.4357 23.0757 23.304 22.7442 23.304H11.9109Z"
      fill="currentColor"
    />
  </svg>
);

export default DocumentText;
