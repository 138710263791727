import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { MemberOverview } from "../types";

const getMemberOverview = (
  userId: number,
  accessToken: string,
  signal: AbortSignal
) =>
  api
    .get<MemberOverview>(`/v1/clinicians/dashboard/members/${userId}`, {
      signal,
      headers: {
        Authorization: accessToken,
      },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useMemberOverview(
  userId: number
): UseQueryResult<MemberOverview, Error> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).memberOverview(userId),
    ({ signal }) => getMemberOverview(userId, accessToken, signal),
    {
      enabled: !!accessToken,
    }
  );
}
