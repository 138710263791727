import { SVGProps } from "react";

const Pencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.7909 3.5057C30.1072 2.82211 29.1799 2.43808 28.2131 2.43808C27.2463 2.43808 26.319 2.82211 25.6353 3.5057L24.0284 5.11265L29.1839 10.2682L30.7909 8.66126C31.4745 7.97755 31.8585 7.05031 31.8585 6.08348C31.8585 5.11665 31.4745 4.18941 30.7909 3.5057V3.5057ZM27.7103 11.7418L22.5547 6.58626L5.67975 23.4613C4.8226 24.318 4.19249 25.3748 3.84642 26.5363L2.7353 30.2654C2.68166 30.4454 2.67766 30.6365 2.72372 30.8185C2.76979 31.0006 2.86421 31.1668 2.99699 31.2996C3.12977 31.4324 3.29598 31.5268 3.47802 31.5728C3.66007 31.6189 3.85118 31.6149 4.03114 31.5613L7.7603 30.4502C8.92173 30.1041 9.97858 29.474 10.8353 28.6168L27.7103 11.7432V11.7418Z"
      fill="currentColor"
    />
  </svg>
);

export default Pencil;
